<template>
    <vx-card :title="'Edit Handover Stock Relocation Items'">
        <div class="vx-row mb-6">
            <vs-button
                class="ml-4 mt-2"
                color="danger"
                icon-pack="feather"
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Details</h4>
                <hr>
                <br>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="stoCode" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Handover Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>STO Type</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="stoType" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Source Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.srcWarehouse ? this.srcWarehouse.code + ' / ' + this.srcWarehouse.name : 'Loading..'" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Dest. Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.destWarehouse ? this.destWarehouse.code + ' / ' + this.destWarehouse.name : 'Loading..'" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Dest. Address</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea :value="this.destWarehouse ? this.destWarehouse.address : 'Loading..'" :readonly="true" class="w-full"></vs-textarea>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Imposition</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.impWarehouse ? this.impWarehouse.code + ' / ' + this.impWarehouse.name : 'Loading..'" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Vehicle Number</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Vehicle
                            :value="this.selectedVehicle"
                            :sto_id="Number(id)"
                            :draw="draw"
                            @selected="(val) => {this.selectedVehicle = val}"
                        ></Vehicle>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Driver</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Driver
                            :value="this.selectedDriver"
                            :sto_id="Number(id)"
                            :draw="draw"
                            @selected="(val) => {this.selectedDriver = val}"
                        ></Driver>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Req. Delivery Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.reqDeliveryDate" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Est. Time Arrived</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="this.eta" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Handover Date (select)</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <datepicker
                            v-model="handoverDate"
                            format="yyyy-MM-dd"
                            input-class="input-class"
                            :disabled-dates="disabledDates"
                        ></datepicker>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Estimate Shipment Cost</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input type="text" :value="shipmentCost" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Cost Ratio (%)</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="costRatio" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Notes</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-textarea v-model="notes" width="100%" :readonly="true" />
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Attachments</h4>
                    <hr>
                    <br>
                    <div class="vx-row mb-3 mt-6" style="margin-left: 0%">
                        <table class="vs-table vs-table--tbody-table">
                            <template>
                                <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                    <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                    <td>
                                        <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                    </td>
                                    <td class="td vs-table--td">
                                        <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-download" 
                                                :disabled="!tr.PathFile"
                                                @click.stop="download(tr)"
                                            />
                                        </vx-tooltip>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </div>
                    <vue-easy-lightbox
                        :visible="visible"
                        :imgs="images"
                        :index="index"
                        @hide="handleHide"
                    />
                    <br>
                    <h4>Approval Matrix</h4>
                    <hr>
                    <vs-divider style="margin-left: 0%">
                        Source Warehouse Approval Status
                    </vs-divider>
                    <div class="vx-row mb-6">
                        <table class="m-3 vs-table vs-table--tbody-table table-bordered" width="100%">
                            <thead class="vs-table--thead">
                                <tr>
                                    <th>Approver</th>
                                    <th>Status</th>
                                    <th>Inbox Time</th>
                                    <th>Action Time</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, index) in srcApproverLines" :key="index" style="padding-bottom: 5px">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.approver }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.status }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.inbox_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.action_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.reason }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br>
                    <vs-divider style="margin-left: 0%" v-if="destApproverLines.length">
                        Destination Warehouse Approval Status
                    </vs-divider>
                    <div class="vx-row mb-6" v-if="destApproverLines.length">
                        <table class="m-3 vs-table vs-table--tbody-table table-bordered" width="100%">
                            <thead class="vs-table--thead">
                                <tr>
                                    <th>Approver</th>
                                    <th>Status</th>
                                    <th>Inbox Time</th>
                                    <th>Action Time</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(tr, index) in destApproverLines" :key="index" style="padding-bottom: 5px">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.approver }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.status }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.inbox_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.action_time }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ tr.reason }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="flex md:flex-row">
            <div class="w-full">
                <h4>Handover Items</h4>
                <hr>
                <div class="vx-row pt-2"></div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-table stripe border style="width:100%" :data="dataLines">
                    <template slot="thead">
                        <vs-th>Sku Description</vs-th>
                        <vs-th>Batch / Exp. Date</vs-th>
                        <vs-th>Quantity</vs-th>
                        <vs-th>HU</vs-th>
                        <vs-th>UOM</vs-th>
                        <vs-th>Total</vs-th>
                        <vs-th><vs-checkbox @input="addAllChecked" v-model="checkedAll"></vs-checkbox>Handed-over</vs-th>
                    </template>

                    <template slot-scope="">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in dataLines">
                            <vs-td>{{ tr.sku_code }} - {{ tr.item_name }}</vs-td>
                            <vs-td>{{ tr.batch }} / {{ tr.exp_date }}</vs-td>
                            <vs-td>{{ tr.amount }}</vs-td>
                            <vs-td>{{ tr.item_unit }}</vs-td>
                            <vs-td>{{ tr.amount_uom }}</vs-td>
                            <vs-td>{{ tr.amount * tr.amount_uom }}</vs-td>
                            <vs-td>
                                <vs-checkbox
                                    @input="addChecked(indextr, tr.id)"
                                    v-model="tr.is_handedover"
                                ></vs-checkbox>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" v-on:click="handleSave" v-if="this.$store.getters['user/hasPermissions']('edit')">Save</vs-button>
                    <vs-button type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
import Vehicle from "./component/Vehicle.vue"
import Driver from "./component/Driver.vue"
import Datepicker from 'vuejs-datepicker';
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        Vehicle,
        Driver,
        Datepicker,
        VueEasyLightbox
    },
    data() {
        return {
            id: 0,
            readonly: true,
            draw: 0,
            stoCode: null,
            stoType: null,
            handoverCode: null,
            handoverDate: null,
            disabledDates: {
                to: new Date(new Date().setDate(new Date().getDate() - 1)) // Semua tanggal sebelum hari ini akan dinonaktifkan
            },
            dataLines: [
                {
                    id: 0,
                    delivery_note_data: "",
                    item_name: "",
                    item_unit: "",
                    amount: 0,
                    dest_address: "",
                    is_handedover: false,
                }
            ],
            checkedAll: false,
            checked: [],
            selectedVehicle: null,
            selectedDriver: null,
            srcWarehouse: null,
            destWarehouse: null,
            impWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            shipmentCost: null,
            costRatio: 0,
            srcApproverLines: [
                {
                    approver: null,
                    status: null,
                    inbox_time: null,
                    action_time: null,
                    reason: null,
                }
            ],
            destApproverLines: [
                {
                    approver: null,
                    status: null,
                    inbox_time: null,
                    action_time: null,
                    reason: null,
                }
            ],

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
        this.$store.dispatch("user/getPermissions", "stock-transfer-order-processing");
    },
    methods: {
        handleUpdateFormat(val) {
            let cleanValue = val.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        handleUpdateCurrency(val) {
            let cleanValue = val.toString().replace(/\D/g, '');
            let formattedValue = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 2
            }).format(cleanValue);

            return formattedValue;
        },
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/outbound/sto-processing/handover-edit/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.stoCode = resp.data.sto_code;
                    this.stoType = resp.data.type;
                    this.handoverCode = resp.data.handover_code;
                    this.selectedVehicle = resp.data.vehicle;
                    this.selectedDriver = resp.data.driver;
                    this.srcWarehouse = resp.data.src_warehouse;
                    this.destWarehouse = resp.data.dest_warehouse;
                    this.impWarehouse = resp.data.imposition_warehouse;
                    this.handoverDate = resp.data.handover_date;
                    this.reqDeliveryDate = resp.data.req_date;
                    this.eta = resp.data.eta;
                    this.shipmentCost = this.handleUpdateCurrency(resp.data.shipment_cost);
                    this.costRatio = resp.data.cost_ratio;
                    this.notes = resp.data.notes;
                    this.srcApproverLines = resp.data.src_approver_reason;
                    this.destApproverLines = resp.data.dest_approver_reason;
                    this.dataLines = resp.data.data_lines;
                    let handovered = [];
                    this.dataLines.forEach((val) => {
                        if (val.is_handedover) {
                            this.checked.push(val.id)
                            handovered.push(val.id)
                        }
                    })
                    if (this.dataLines.length == handovered.length) {
                        this.checkedAll = true
                    }

                    if (resp.data.attachment) {
                        resp.data.attachment.forEach((val) => {
                            this.fileAttachment.push({
                                id: val.id,
                                NameFile: val.file_name,
                                PathFile: val.file_url,
                            });
                            this.images.push(val.file_url);
                        });
                    }

                    this.draw++;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        addAllChecked() {
            if (this.checkedAll) {
                this.checked = []
                this.dataLines.forEach(val => {
                    val.is_handedover = this.checkedAll
                    this.checked.push(val.id)
                })
            } else {
                this.checked = []
                this.dataLines.forEach(val => {
                    val.is_handedover = this.checkedAll
                })
            }
        },
        addChecked(index, val) {
            if (this.dataLines[index].is_handedover) {
                this.checked.push(val)
            } else {
                this.checked.splice(this.checked.indexOf(val), 1);
            }

            if (this.checked.length == this.dataLines.length) {
                this.checkedAll = true
            } else {
                this.checkedAll = false
            }
        },
        handleSave() {
            if (!this.selectedVehicle) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Vehicle!",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedDriver) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Driver!",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to update this Handover Document?",
                accept: () => {
                    this.$vs.loading()
                    this.$http.post("/api/wms/v1/outbound/sto-processing/save-handover/" + this.id, {
                        selected_vehicle: this.selectedVehicle.id,
                        selected_driver: this.selectedDriver.id,
                        handover_date: this.formatDate(this.handoverDate),
                        line_ids: this.checked,
                    })
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                title: "Success",
                                text: "",
                                color: "success",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check"
                            });
                            this.$vs.loading.close();
                            this.handleBack();
                        } else {
                            this.$vs.notify({
                                title: "Danger",
                                text: resp.message,
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check"
                            });
                            this.$vs.loading.close();
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            });
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-processing",
            });
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
}
</script>